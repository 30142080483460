import { useCallback } from "react";
import { Button, ButtonGroup, Form, InputGroup, Table } from "react-bootstrap";
import type { TradeRequest } from "../../../api/src/rebalances/rebalances.service";
import { setCollectionFieldHandler } from "../lib/forms";
import { formatCurrency } from "../lib/numbers";

const emptyTrade: TradeRequest = {
  rebalanceId: 0,
  accountId: 0,
  symbol: "",
  price: 0,
  amount: 0,
  side: "buy",
  isMarketTrade: true,
};

/**
 * A multi-item form component for trades on a rebalance. This component
 * displays an arbitrary number of rows for users to view and modify trades.
 */
const TradesEditor = ({
  trades,
  setTrades,
  disabled = false,
}: {
  /** The collection of trades for a model */
  trades: TradeRequest[];
  /** The setter function for the trades prop */
  setTrades: (trades: TradeRequest[]) => void;
  /** Disable the ability to edit trades if true */
  disabled?: boolean;
}) => {
  const addTrade = useCallback(() => {
    setTrades([...trades, { ...emptyTrade }]);
  }, [trades, setTrades]);

  const removeTrade = useCallback(
    (index: number) => {
      return () => {
        setTrades([...trades.slice(0, index), ...trades.slice(index + 1)]);
      };
    },
    [trades, setTrades],
  );

  return (
    <Table className="form-table align-top">
      <thead>
        <tr>
          <th>Symbol</th>
          <th>Side</th>
          <th>Price</th>
          <th>Shares</th>
          <th className="text-end">Total</th>
          {disabled ? null : <th>Add/Delete</th>}
        </tr>
      </thead>
      <tbody>
        {(trades ?? []).map((trade, index) => (
          <tr key={index}>
            <td>
              <Form.Control
                type="text"
                value={trade.symbol}
                required
                onChange={setCollectionFieldHandler(
                  index,
                  "symbol",
                  trades,
                  setTrades,
                )}
                disabled={disabled}
                className={trade.isMarketTrade ? "" : "border-warning"}
              />
              {trade.isMarketTrade ? null : (
                <Form.Text className="text-danger d-block">
                  This security is not tradeable by custodian and will{" "}
                  <em>not</em> be included in trade execution.
                </Form.Text>
              )}
            </td>
            <td>
              <Form.Select
                value={trade.side}
                placeholder="Select trade side"
                onChange={setCollectionFieldHandler(
                  index,
                  "side",
                  trades,
                  setTrades,
                )}
                disabled={disabled}
              >
                <option value="buy">Buy</option>
                <option value="sell">Sell</option>
              </Form.Select>
            </td>
            <td>
              <InputGroup>
                <InputGroup.Text>$</InputGroup.Text>
                <Form.Control
                  value={trade.price}
                  required
                  onChange={setCollectionFieldHandler(
                    index,
                    "price",
                    trades,
                    setTrades,
                  )}
                  disabled={disabled}
                />
              </InputGroup>
            </td>
            <td>
              <Form.Control
                type="number"
                min="1"
                value={trade.amount}
                required
                onChange={setCollectionFieldHandler(
                  index,
                  "amount",
                  trades,
                  setTrades,
                )}
                disabled={disabled}
              />
            </td>
            <td className="text-end">
              {formatCurrency(trade.amount * trade.price, 2)}
            </td>
            {disabled ? null : (
              <td>
                <ButtonGroup>
                  <Button onClick={removeTrade(index)} className="fw-bold">
                    –
                  </Button>
                  {index !== trades.length - 1 ? null : (
                    <Button onClick={addTrade} className="fw-bold">
                      +
                    </Button>
                  )}
                </ButtonGroup>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default TradesEditor;
