import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import * as yup from "yup";
import type { EditableHouseholdTrading } from "../../../../api/src/households-base/households-base.service";
import Loading from "../../Loading";
import { NotificationContext } from "../../Notifications";
import DateInput from "../../components/DateInput";
import FormFieldError from "../../components/FormFieldError";
import LabelValuePair from "../../components/LabelValuePair";
import SubmitButton from "../../components/SubmitButton";
import { InlineError, naLabel } from "../../lib/display";
import {
  getSchemaFieldLabel,
  idOptionalSelectorSchema,
  riskScoreSchema,
  securityRestrictionSchema,
  securityRestrictionTreatmentSchema,
  undefinedNumberSchema,
} from "../../lib/forms";
import { formatCurrencyComponent } from "../../lib/numbers";
import { useQueryModels } from "../../models/lib";
import RebalanceExecutedTable from "../../rebalances/RebalancesExecutedTable";
import HouseholdAssetAllocationChart from "./HouseholdAssetAllocationChart";
import { HouseholdContext } from "./HouseholdInfo";
import HouseholdTradingHoldingsTable from "./HouseholdTradingHoldingsTable";
import { useUpdateHouseholdModelTrading } from "./lib";
import { HouseholdWithAccounts } from "../../../../api/src/households/households.service";

const schema: yup.ObjectSchema<EditableHouseholdTrading> = yup.object({
  assignedModelId: idOptionalSelectorSchema.label("Assigned Model"),
  securityRestrictions: securityRestrictionSchema.required(),
  rebalanceAllocationThreshold: undefinedNumberSchema
    .min(0)
    .max(100)
    .label("Rebalance Allocation Threshold"),
  rebalanceWeightLimit: undefinedNumberSchema
    .min(0)
    .max(100)
    .label("Rebalance Weight Limit"),
  rebalancePeriod: yup
    .string()
    .required()
    .oneOf(["d", "w", "m", "q", "y"])
    .label("Rebalance Recurrence"),
  rebalancePeriodStartDate: yup
    .date()
    .required()
    .label("Rebalance Recurrence Start Date"),
  holdings: yup
    .array()
    .required()
    .of(
      yup.object({
        accountId: yup.number().required(),
        restrictSecurity: yup.bool().required(),
        symbol: yup.string().required(),
        treatment: securityRestrictionTreatmentSchema,
      }),
    ),
  accounts: yup.lazy((obj) =>
    yup.object(
      _.mapValues(obj, () =>
        yup.object({
          accountId: yup.number().required(),
          assignedModelId: idOptionalSelectorSchema,
          riskScore: riskScoreSchema,
          capitalGainsBudgetST: idOptionalSelectorSchema.min(0),
          capitalGainsBudgetLT: idOptionalSelectorSchema.min(0),
        }),
      ),
    ),
  ),
});

const HouseholdModelTrading = ({
  household: propHousehold,
  isEditable = true,
}: {
  household?: HouseholdWithAccounts;
  isEditable?: boolean;
}) => {
  const { household: contextHousehold } = useContext(HouseholdContext);
  const household = propHousehold ?? contextHousehold;
  const householdId = household?.id ?? 0;
  const householdIdStr = householdId.toString();

  const form = useForm<EditableHouseholdTrading>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
    control,
  } = form;

  const accounts = useMemo(() => household?.accounts, [household?.accounts]);
  const holdings = useMemo(
    () => (accounts ?? []).flatMap((account) => account.holdings ?? []),
    [accounts],
  );

  useEffect(() => {
    if (
      typeof household !== "undefined" &&
      typeof holdings !== "undefined" &&
      typeof accounts !== "undefined"
    ) {
      const accountsWithId = accounts.map((account) => ({
        ...account,
        accountId: account.id,
      }));
      const accountsMap = _.keyBy(accountsWithId, "id");

      reset({
        assignedModelId: household.assignedModelId,
        securityRestrictions: household.securityRestrictions,
        rebalanceAllocationThreshold: household.rebalanceAllocationThreshold,
        rebalanceWeightLimit: household.rebalanceWeightLimit,
        rebalancePeriod: household.rebalancePeriod,
        rebalancePeriodStartDate: household.rebalancePeriodStartDate,
        holdings: holdings.map((holding) => {
          const symbol = holding.security?.identifier ?? "";
          const account = accountsMap[holding.accountId];
          const securityRestriction = account?.securityRestrictions.find(
            (restriction) => restriction.symbol === symbol,
          );
          const restrictSecurity = typeof securityRestriction !== "undefined";

          return {
            ...holding,
            symbol,
            restrictSecurity,
            treatment: securityRestriction?.treatment ?? "sell_no_tax",
          };
        }),
        accounts: accountsMap,
      });
    }
  }, [accounts, holdings, household, reset]);

  const {
    isPending: isPendingModels,
    isError: isErrorModels,
    data: modelsBody,
  } = useQueryModels();

  const modelOptions = [
    <option key={null} value="">
      None
    </option>,
    ...(modelsBody?.data ?? [])
      .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
      .map((model) => (
        <option key={model.id} value={model.id}>
          {model.name}
        </option>
      )),
  ];

  const { mutateAsync: updateHousehold } =
    useUpdateHouseholdModelTrading(householdId);

  const notificationContext = useContext(NotificationContext);

  const onSubmit = useCallback(
    async (data: EditableHouseholdTrading) => {
      try {
        await updateHousehold(data);

        notificationContext.pushNotification({
          id: `household-${householdId}`,
          header: "Household Updated",
          body: `${household?.name} updated`,
          variant: "success",
        });
      } catch (err) {
        console.error("Failed to save household", err);
        notificationContext.pushNotification({
          id: `household-${householdId}`,
          header: "Failed to Save Household",
          body: `${household?.name} was not saved`,
          variant: "danger",
        });
      }
    },
    [household?.name, householdId, notificationContext, updateHousehold],
  );

  return (
    <FormProvider {...form}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xl={4}>
            <h3>Model Details</h3>
            <LabelValuePair
              className="mb-3"
              label={getSchemaFieldLabel(schema.fields.assignedModelId)}
              value={
                <>
                  {isPendingModels ? (
                    <Loading />
                  ) : isErrorModels ? (
                    <InlineError>There was an error loading models</InlineError>
                  ) : (
                    <Form.Select
                      placeholder="Select assigned model"
                      {...register("assignedModelId")}
                      disabled={!isEditable}
                    >
                      {modelOptions}
                    </Form.Select>
                  )}
                  <FormFieldError field={errors.assignedModelId} />
                </>
              }
              orientation="horizontal"
              widths={{ label: { lg: 6 } }}
              plaintext={isErrorModels}
            />
            <LabelValuePair
              className="mb-3"
              label={getSchemaFieldLabel(
                schema.fields.rebalanceAllocationThreshold,
              )}
              value={
                <InputGroup>
                  <Form.Control
                    type="number"
                    min={0}
                    max={100}
                    {...register("rebalanceAllocationThreshold")}
                    disabled={!isEditable}
                  />
                  <InputGroup.Text>%</InputGroup.Text>
                </InputGroup>
              }
              orientation="horizontal"
              widths={{ label: { lg: 6 } }}
            />
            <LabelValuePair
              className="mb-3"
              label={getSchemaFieldLabel(schema.fields.rebalanceWeightLimit)}
              value={
                <InputGroup>
                  <Form.Control
                    type="number"
                    min={0}
                    max={100}
                    {...register("rebalanceWeightLimit")}
                    disabled={!isEditable}
                  />
                  <InputGroup.Text>%</InputGroup.Text>
                </InputGroup>
              }
              orientation="horizontal"
              widths={{ label: { lg: 6 } }}
            />
            <LabelValuePair
              className="mb-3"
              label={getSchemaFieldLabel(schema.fields.rebalancePeriod)}
              value={
                <Form.Select
                  placeholder="Select rebalance period"
                  {...register("rebalancePeriod")}
                  disabled={!isEditable}
                >
                  <option value="d">Daily</option>
                  <option value="w">Weekly</option>
                  <option value="m">Monthly</option>
                  <option value="q">Quarterly</option>
                  <option value="y">Yearly</option>
                </Form.Select>
              }
              orientation="horizontal"
              widths={{ label: { lg: 6 } }}
            />
            <LabelValuePair
              className="mb-3"
              label={getSchemaFieldLabel(
                schema.fields.rebalancePeriodStartDate,
              )}
              value={
                <DateInput
                  name="rebalancePeriodStartDate"
                  control={control}
                  isEditable={isEditable}
                />
              }
              orientation="horizontal"
              widths={{ label: { lg: 6 } }}
            />
            <LabelValuePair
              label="Realized Gains YTD"
              value={formatCurrencyComponent(
                (household?.accounts ?? []).reduce(
                  (sum, account) =>
                    sum + (account.performanceYTD?.realizedGainLoss ?? 0),
                  0,
                ),
              )}
              orientation="horizontal"
              widths={{ label: { lg: 6 } }}
              plaintext
            />
            <LabelValuePair
              label="Last Rebalance Date"
              value={
                typeof household?.lastRebalanceDate === "undefined" ||
                household.lastRebalanceDate === null
                  ? naLabel
                  : household.lastRebalanceDate.toLocaleDateString()
              }
              orientation="horizontal"
              widths={{ label: { lg: 6 } }}
              plaintext
            />
            <LabelValuePair
              label="Pending Rebalance"
              value={
                typeof household?.latestRebalanceDate === "undefined" ||
                household.latestRebalanceDate === null ? (
                  naLabel
                ) : (
                  <Link to={`/rebalances/${household.latestRebalanceId}`}>
                    {household.latestRebalanceDate.toLocaleDateString()}
                  </Link>
                )
              }
              orientation="horizontal"
              widths={{ label: { lg: 6 } }}
              plaintext
            />
          </Col>
          <Col>
            <h3>Allocation Details</h3>
            <HouseholdAssetAllocationChart householdId={householdId} />
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            {isEditable && <SubmitButton isSubmitting={isSubmitting} />}
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <h3>Holdings</h3>
            <HouseholdTradingHoldingsTable
              holdings={holdings}
              accounts={accounts}
              isEditable={isEditable}
            />
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            {isEditable && <SubmitButton isSubmitting={isSubmitting} />}
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <h3>Rebalance History</h3>
            <RebalanceExecutedTable householdId={householdIdStr} />
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};

export default HouseholdModelTrading;
