import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import type { AccountsController } from "../../../../api/src/accounts/accounts.controller";
import type { Account } from "../../../../api/src/accounts/accounts.service";
import type { SerializedObject, UnpackResponse } from "../../../../api/src/lib";
import FormFieldError from "../../components/FormFieldError";
import SubmitButton from "../../components/SubmitButton";
import { useAuthenticatedMutationNew } from "../../lib/api";
import { boolToYesNo } from "../../lib/display";
import { NotificationContext } from "../../Notifications";

const EDITABLE_ACCOUNT_TYPES = [
  "Trust",
  "Tenants by the Entirety",
  "Unknown",
  "",
];

const schema: yup.ObjectSchema<{ isTaxable: boolean }> = yup.object({
  isTaxable: yup.boolean().required().label("Is Taxable"),
});

function useUpdateAccountTaxable(accountId: number) {
  return useAuthenticatedMutationNew<
    SerializedObject<UnpackResponse<AccountsController["updateTaxable"]>>,
    void,
    { isTaxable: boolean }
  >({
    mutationKey: ["PUT", "accounts", accountId, "taxable"],
    mutationFn: () => null,
  });
}

const AccountTaxableField = ({
  account,
  accountId,
}: {
  account?: Account;
  accountId?: number;
}) => {
  const {
    handleSubmit,
    setFocus,
    formState: { errors, isSubmitting },
    reset,
    setValue,
    watch,
  } = useForm<{ isTaxable: boolean }>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset({ isTaxable: account?.isTaxable ?? false });
  }, [account?.isTaxable, reset]);

  const updateAccountTaxable = useUpdateAccountTaxable(accountId ?? -1);

  const queryClient = useQueryClient();
  const notificationContext = useContext(NotificationContext);

  const [enableField, setEnableField] = useState(false);

  const onSubmit = useCallback(
    async ({ isTaxable }: { isTaxable: boolean }) => {
      try {
        await updateAccountTaxable.mutateAsync({ isTaxable });
        setEnableField(false);
        queryClient.setQueryData(
          ["accounts", accountId ?? -1, { includeBalances: true }],
          { ...account, isTaxable },
        );
        reset({ isTaxable });
        notificationContext.pushNotification({
          id: `account-${accountId}-taxable`,
          header: "Account Taxability Updated",
          body: `${account?.displayName} updated`,
          variant: "success",
        });
      } catch (err) {
        console.error("Failed to save account taxability", err);
        notificationContext.pushNotification({
          id: `account-${accountId}-taxable`,
          header: "Failed to Update Account Taxability",
          body: `${account?.displayName} was not saved`,
          variant: "danger",
        });
      }
    },
    [
      account,
      accountId,
      notificationContext,
      queryClient,
      reset,
      updateAccountTaxable,
    ],
  );

  const onSave = useCallback(
    (ev: React.MouseEvent) => {
      if (!enableField) {
        ev.preventDefault();
        setEnableField(true);
      }
    },
    [enableField],
  );

  // Focus the name field on toggle. This does not work if we call
  // it before the re-render that displays the input field, so we
  // have to watch it using the effect hook.
  useEffect(() => {
    if (enableField) {
      setFocus("isTaxable");
    }
  }, [enableField, setFocus]);

  const isTaxable = watch("isTaxable");

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex align-items-center">
        <div style={{ maxWidth: 360 }}>
          {enableField ? null : <span>{boolToYesNo(isTaxable)}</span>}
          <Form.Group controlId="form-isTaxable" hidden={!enableField}>
            <Form.Select
              value={Number(isTaxable)}
              onChange={(ev) =>
                setValue("isTaxable", ev.currentTarget.value === "1")
              }
            >
              <option value={1}>Yes</option>
              <option value={0}>No</option>
            </Form.Select>
            <FormFieldError field={errors.isTaxable} />
          </Form.Group>
        </div>
        {typeof account === "undefined" ||
        !EDITABLE_ACCOUNT_TYPES.includes(account.type) ? null : (
          <SubmitButton
            variant="icon"
            icon={enableField ? "/icons/save.svg" : "/icons/edit.svg"}
            label={enableField ? "Save" : "Edit"}
            type={enableField ? "submit" : "button"}
            onClick={onSave}
            isSubmitting={isSubmitting}
            className="ms-2 me-3"
          />
        )}
      </div>
    </Form>
  );
};

export default AccountTaxableField;
